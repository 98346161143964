@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';
@font-face {
    font-family: 'outfitMedium';
    src: url('../src/template/fonts/Outfit-Medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: 'outfitRegular';
    src: url('../src/template/fonts/Outfit-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'outfitLight';
    src: url('../src/template/fonts/Outfit-Light.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  body {
    font-family: 'outfitLight', sans-serif;
  }
  h1{
    font-weight: 900;
  }
  h1,h2,h3,h4,h5,h6{
    font-family: 'outfitMedium', sans-serif;
  }

:root {
    --primary:#00A7E7;
    --white:#fff;
    --gray:lightgray;
    --black:#000;
    --secondary: #26539F;
}

body::before {
    content: "";
    /*background-image: url(../src/template/images/background.jpg);*/
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.1; /* Adjust the value (0.0 to 1.0) for opacity */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}
body, html {
    margin:0;
    padding:0;
    box-sizing: border-box;
    /*font-family: 'Inter', sans-serif;*/
}

.flex-center {
    display: flex;
    justify-content:center;
    align-items:center;
}

.flex-align-center {
    display:flex;
    align-items:center;
}

.flex-justify-center {
    display: flex;
    justify-content: center;
}

.style-none {
    list-style-type: none;
    text-decoration: none;
}

.text-mute {
    color:gray;
}

.text-center {
    text-align: center;
}

.primay-color {
    color:var(--primary);
}

.w-50 {
    width:50%;
}

.py-10 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.d-flex {
    display:flex;
}
.top_security {
    display: flex;
    justify-content: flex-end;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 25px;
    padding: 0px 20px;
  }
  .top_sec_p {
    text-align: center;
    background-color: var(--secondary);
    padding: 10px 40px;
    width: 40%;
    color: #fff;
  }
  @media (max-width: 767px){
    .top_sec_p {
      padding: 10px 20px;
      width: 100%;
    }
  }
  .secondary{
    color: var(--secondary);
  }
  .primary{
    color: var(--primary);
  }
  .mt_50{
    margin-top: 50px;
  }
  
.form-control,.form-select{
    border-radius: 0px;
}


.loaders, #global-loader1 {
  position: fixed;
  top: 50%; /* Center the loader vertically */
  left: 50%; /* Center the loader horizontally */
  transform: translate(-50%, -50%); /* Center the loader perfectly */
  z-index: 99;
  width: 100%;
  height: 100%; /* Make the loader cover the entire viewport */
  background: #2752a073; /* Semi-transparent background */
}

.loaders img, #global-loader1 img {
  position: absolute; /* Position the loader image absolutely */
  top: 50%; /* Center the image vertically */
  left: 50%; /* Center the image horizontally */
  width: 100px;
  transform: translate(-50%, -50%); /* Center the image perfectly */
  border-radius: 15px;
}